import React from 'react';
import { President } from '../types/president';
import { chartStyles } from '../components/styles';
import { DataType } from './ChartControls';

interface PartyPerformanceProps {
  presidentData: President[];
  overallAverage: number;
  dataType?: DataType;
}

interface PartyAverages {
  democrat: {
    average: number;
    count: number;
    relativeToOverall: number;
  };
  republican: {
    average: number;
    count: number;
    relativeToOverall: number;
  };
}

const calculatePartyAverages = (presidents: President[], overallAverage: number): PartyAverages => {
  const democratData = presidents.filter(p => p.party === 'Democrat');
  const republicanData = presidents.filter(p => p.party === 'Republican');

  const democratAvg = democratData.reduce((sum, pres) => sum + pres.averageMonthlyIncrease, 0) / democratData.length;
  const republicanAvg = republicanData.reduce((sum, pres) => sum + pres.averageMonthlyIncrease, 0) / republicanData.length;

  return {
    democrat: {
      average: democratAvg,
      count: democratData.length,
      relativeToOverall: democratAvg - overallAverage
    },
    republican: {
      average: republicanAvg,
      count: republicanData.length,
      relativeToOverall: republicanAvg - overallAverage
    }
  };
};

export const PartyPerformance: React.FC<PartyPerformanceProps> = ({ presidentData, overallAverage, dataType }) => {
  const partyAverages = calculatePartyAverages(presidentData, overallAverage);
  const isUnemployment = dataType === 'unemployment';
  const unit = isUnemployment ? ' percentage points' : '%';

  return (
    <div style={chartStyles.partySummaryContainer}>
      <h2 style={chartStyles.sectionTitle}>Party Performance Summary</h2>
      <div style={chartStyles.partyGridContainer}>
        <div style={chartStyles.democratCard}>
          <h3 style={chartStyles.democratTitle}>Democratic Party</h3>
          <p style={chartStyles.partyText}>Presidents: {partyAverages.democrat.count}</p>
          <p style={chartStyles.partyStatsText}>
            Avg Monthly Change: {partyAverages.democrat.average > 0 ? '+' : ''}{partyAverages.democrat.average.toFixed(2)}{unit}
          </p>
          <p style={chartStyles.partyRelativeText}>
            Relative to Overall Avg: {partyAverages.democrat.relativeToOverall > 0 ? '+' : ''}{partyAverages.democrat.relativeToOverall.toFixed(2)}{unit}
          </p>
        </div>

        <div style={chartStyles.republicanCard}>
          <h3 style={chartStyles.republicanTitle}>Republican Party</h3>
          <p style={chartStyles.partyText}>Presidents: {partyAverages.republican.count}</p>
          <p style={chartStyles.partyStatsText}>
            Avg Monthly Change: {partyAverages.republican.average > 0 ? '+' : ''}{partyAverages.republican.average.toFixed(2)}{unit}
          </p>
          <p style={chartStyles.partyRelativeText}>
            Relative to Overall Avg: {partyAverages.republican.relativeToOverall > 0 ? '+' : ''}{partyAverages.republican.relativeToOverall.toFixed(2)}{unit}
          </p>
        </div>
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { useChartData } from '../hooks/useChartData';
import { ChartControls, DataType } from './ChartControls';
import { PartyPerformance } from './PartyPerformance';
import { PresidentSummary } from './PresidentSummary';
import { chartStyles } from './styles';

interface ChartDataSource {
  id: string;
  title: string;
  yAxisLabel: string;
  averageChangeLabel: string;
  tootltipFormat: (value: number) => string;
}

const chart_data_sources = [
  {
    id: 'sp500',
    title: 'S&P 500 Historical Data',
    yAxisLabel: 'S&P 500 Price',
    averageChangeLabel: 'Overall Average Monthly Change: ',
    tooltipFormat: (value: number) => `$${value.toFixed(2)}`,
  },
  {
    id: 'electricity',
    title: 'Electricity Prices by President',
    yAxisLabel: 'Electricity Price ($/kWh)',
    averageChangeLabel: 'Overall Average Monthly Change: ',
    tooltipFormat: (value: number) => `$${value.toFixed(2)}`,
  },
  {
    id: 'unemployment',
    title: 'Unemployment Rate by President',
    yAxisLabel: 'Unemployment Rate (%)',
    averageChangeLabel: 'Overall Average Monthly Change: ',
    tooltipFormat: (value: number) => `${value.toFixed(2)}%`,
  },
  {
    id: 'gas',
    title: 'Gas Prices by President',
    yAxisLabel: 'Gas Price ($)',
    averageChangeLabel: 'Overall Average Monthly Change: ',
    tooltipFormat: (value: number) => `$${value.toFixed(2)}`,
  },
  {
    id: 'egg',
    title: 'Egg Prices by President',
    yAxisLabel: 'Egg Price ($)',
    averageChangeLabel: 'Overall Average Monthly Change: ',
    tooltipFormat: (value: number) => `$${value.toFixed(2)}`,
  },
  {
    id: 'incomeQ1',
    title: 'Income After Taxes (Bottom Quintile) by President', 
    yAxisLabel: 'Income ($)',
    averageChangeLabel: 'Overall Average Monthly Change: ',
    tooltipFormat: (value: number) => `$${value.toFixed(2)}`,
  },
  {
    id: 'incomeQ2',
    title: 'Income After Taxes (Second Quintile) by President',
    yAxisLabel: 'Income ($)',
    averageChangeLabel: 'Overall Average Monthly Change: ',
    tooltipFormat: (value: number) => `$${value.toFixed(2)}`,
  },
  {
    id: 'incomeQ3',
    title: 'Income After Taxes (Third Quintile) by President',
    yAxisLabel: 'Income ($)',
    averageChangeLabel: 'Overall Average Monthly Change: ',
    tooltipFormat: (value: number) => `$${value.toFixed(2)}`,
  },
  {
    id: 'incomeQ4',
    title: 'Income After Taxes (Fourth Quintile) by President',
    yAxisLabel: 'Income ($)',
    averageChangeLabel: 'Overall Average Monthly Change: ',
    tooltipFormat: (value: number) => `$${value.toFixed(2)}`,
  },
  {
    id: 'incomeQ5',
    title: 'Income After Taxes (Top Quintile) by President',
    yAxisLabel: 'Income ($)',
    averageChangeLabel: 'Overall Average Monthly Change: ',
    tooltipFormat: (value: number) => `$${value.toFixed(2)}`,
  },
]

const HistoricalDataChart: React.FC = () => {
  const [currentRange, setCurrentRange] = useState<string>('all');
  const [dataType, setDataType] = useState<DataType>('gas');

  const { data, loading, error, handleRangeChange } = useChartData(dataType);
  const { visibleData, presidentData, overallAverage } = data;

  const getChartTitle = () => {
    const source = chart_data_sources.find(source => source.id === dataType);
    return source ? source.title : '';
  }

  const getYAxisLabel = () => {
    const source = chart_data_sources.find(source => source.id === dataType);
    return source ? source.yAxisLabel : '';
  };

  const formatTooltipValue = (value: number) => {
    const source = chart_data_sources.find(source => source.id === dataType);
    return source ? source.tooltipFormat(value) : '';
  };

  const getAverageChangeLabel = () => {
    const source = chart_data_sources.find(source => source.id === dataType);
    return source ? source.averageChangeLabel : '';
  };

  if (loading) {
    return <div style={{ padding: '20px', textAlign: 'center', fontSize: '18px' }}>Loading data...</div>;
  }

  if (error) {
    return <div style={{ padding: '20px', textAlign: 'center', color: 'red', fontSize: '18px' }}>Error: {error}</div>;
  }

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div style={{ 
          backgroundColor: 'rgba(255, 255, 255, 0.95)', 
          padding: '10px', 
          border: '1px solid #ccc',
          fontSize: '12px'
        }}>
          <p style={{ margin: '0 0 5px 0' }}><strong>Date:</strong> {label}</p>
          <p style={{ margin: '0 0 5px 0' }}>
            <strong>{dataType === 'unemployment' ? 'Rate' : 'Price'}:</strong> {formatTooltipValue(data.price)}
          </p>
          <p style={{ margin: '0' }}><strong>President:</strong> {data.president}</p>
        </div>
      );
    }
    return null;
  };

  const CustomizedDot = (props: any) => {
    const { cx, cy, payload } = props;
    if (cx === null || cy === null || isNaN(cx) || isNaN(cy)) {
      return null;
    }
    return (
      <circle 
        cx={cx} 
        cy={cy} 
        r={2} 
        fill={payload.party === 'Democrat' ? '#0f8b8d' : '#b0413e'} 
      />
    );
  };

  // Calculate gradient stops for sharp transitions
  const calculateGradientStops = () => {
    const stops: Array<{ offset: string; color: string }> = [];
    const totalDataPoints = visibleData.length;

    presidentData.forEach((president, index) => {
      // Find the first data point for this president
      const firstDataPoint = visibleData.findIndex(d => d.president === president.name);
      
      // Find the last data point for this president by searching from the end
      let lastDataPoint = -1;
      for (let i = visibleData.length - 1; i >= 0; i--) {
        if (visibleData[i].president === president.name) {
          lastDataPoint = i;
          break;
        }
      }

      if (firstDataPoint !== -1 && lastDataPoint !== -1) {
        const startOffset = (firstDataPoint / totalDataPoints) * 100;
        const endOffset = (lastDataPoint / totalDataPoints) * 100;
        const color = president.party === 'Democrat' ? '#0f8b8d' : '#b0413e';

        // Add a stop at the start of this president's term
        if (index > 0) {
          stops.push({ offset: `${startOffset}%`, color });
        }
        // Add a stop slightly before the end to maintain color
        if (index < presidentData.length - 1) {
          stops.push({ offset: `${endOffset}%`, color });
        } else {
          // For the last president, extend to the end
          stops.push({ offset: '100%', color });
        }
      }
    });

    // Add initial stop if not present
    if (stops.length > 0 && stops[0].offset !== '0%') {
      stops.unshift({ offset: '0%', color: stops[0].color });
    }

    return stops;
  };

  return (
    <div style={chartStyles.container}>
      <h1 style={chartStyles.title}>{getChartTitle()}</h1>
      
      <div style={chartStyles.chartContainer}>
        <ChartControls
          dataType={dataType}
          currentRange={currentRange}
          onDataTypeChange={setDataType}
          onRangeChange={(range) => {
            setCurrentRange(range);
            handleRangeChange(range);
          }}
        />
          <ResponsiveContainer width='100%' height={550}>
            <LineChart
              data={visibleData}
              margin={{ top: 20, right: 50, left: 20, bottom: 60 }}
            >
              <CartesianGrid 
                strokeDasharray="3 3" 
                stroke="rgba(20, 54, 66, 0.2)"
                strokeWidth={1.5}
              />
              <XAxis 
                dataKey="date"
                type="category"
                tick={{ fontSize: 12, fill: '#143642' }}
                padding={{ left: 30, right: 30 }}
                stroke="#143642"
              />
              <YAxis 
                scale={dataType === 'unemployment' ? 'linear' : 'log'}
                domain={dataType === 'unemployment' ? [0, 'auto'] : ['auto', 'auto']}
                tick={{ fontSize: 12, fill: '#143642' }}
                stroke="#143642"
                label={{ 
                  value: getYAxisLabel(), 
                  angle: -90, 
                  position: 'insideLeft', 
                  offset: -5,
                  style: { fill: '#143642' }
                }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend 
                verticalAlign="bottom" 
                height={36}
                wrapperStyle={{ paddingTop: '20px' }}
              />
              <Line
                type="monotone"
                dataKey="price"
                name={dataType === 'unemployment' ? 'Rate' : 'Price'}
                dot={<CustomizedDot />}
                stroke="url(#colorGradient)"
                strokeWidth={2}
                isAnimationActive={false}
              />
              <defs>
                <linearGradient id="colorGradient">
                  {calculateGradientStops().map((stop, index) => (
                    <stop
                      key={index}
                      offset={stop.offset}
                      stopColor={stop.color}
                    />
                  ))}
                </linearGradient>
              </defs>
            </LineChart>
          </ResponsiveContainer>
      </div>

      <h3 style={{
        ...chartStyles.subtitle, 
        marginTop: '0',
        marginBottom: '20px',
        fontSize: '18px',
        padding: '10px',
        borderTop: '1px solid #e0e0e0',
        borderBottom: '1px solid #e0e0e0'
      }}>
        {getAverageChangeLabel()}
      </h3>

      <PartyPerformance presidentData={presidentData} overallAverage={overallAverage} dataType={dataType} />
      <PresidentSummary presidentData={presidentData} overallAverage={overallAverage} dataType={dataType} />
    </div>
  );
};

export default HistoricalDataChart;

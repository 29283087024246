export const chartStyles = {
  container: {
    padding: '20px',
    maxWidth: '1400px',
    margin: '0 auto'
  },
  title: {
    textAlign: 'center' as const,
    marginBottom: '10px',
    fontSize: '28px',
    color: '#143642'
  },
  subtitle: {
    textAlign: 'center' as const,
    marginBottom: '30px',
    color: '#7d6167'
  },
  chartContainer: {
    width: '100%',
    marginBottom: '10px',
    position: 'relative' as const,
    aspectRatio: '16/9'
  },
  controlsContainer: {
    textAlign: 'center' as const,
    marginBottom: '20px',
    padding: '15px',
    borderRadius: '8px'
  },
  toggleContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    alignItems: 'center',
    marginBottom: '10px'
  },
  toggleLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    cursor: 'pointer',
    marginRight: '20px',
    color: '#143642'
  },
  checkbox: {
    cursor: 'pointer'
  },
  select: {
    padding: '8px 16px',
    fontSize: '14px',
    border: '1px solid #7d6167',
    borderRadius: '4px',
    backgroundColor: '#dad2d8',
    color: '#143642',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    outline: 'none',
    minWidth: '200px'
  },
  rangeButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    marginTop: '10px'
  },
  rangeButton: {
    padding: '8px 16px',
    backgroundColor: '#dad2d8',
    color: '#143642',
    border: '1px solid #7d6167',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'all 0.2s ease'
  },
  activeRangeButton: {
    backgroundColor: '#0f8b8d',
    color: 'white',
    border: '1px solid #0f8b8d'
  },
  partySummaryContainer: {
    marginBottom: '40px',
    padding: '20px'
  },
  sectionTitle: {
    textAlign: 'center' as const,
    marginBottom: '30px',
    fontSize: '24px',
    color: '#143642'
  },
  partyGridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '20px',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px'
  },
  democratCard: {
    padding: '20px',
    borderRadius: '10px',
    backgroundColor: '#dad2d8',
    border: '2px solid #0f8b8d',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    margin: '10px'
  },
  republicanCard: {
    padding: '20px',
    borderRadius: '10px',
    backgroundColor: '#dad2d8',
    border: '2px solid #b0413e',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    margin: '10px'
  },
  democratTitle: {
    margin: '0 0 15px 0',
    color: '#0f8b8d',
    textAlign: 'center' as const,
    fontSize: '20px'
  },
  republicanTitle: {
    margin: '0 0 15px 0',
    color: '#b0413e',
    textAlign: 'center' as const,
    fontSize: '20px'
  },
  partyText: {
    margin: '10px 0',
    textAlign: 'center' as const,
    color: '#143642'
  },
  partyStatsText: {
    margin: '10px 0',
    textAlign: 'center' as const,
    fontWeight: 'bold',
    fontSize: '16px',
    color: '#143642'
  },
  partyRelativeText: {
    margin: '10px 0',
    textAlign: 'center' as const,
    fontSize: '14px',
    color: '#7d6167'
  },
  presidentSummaryContainer: {
    marginTop: '40px',
    padding: '20px'
  },
  presidentGridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
    gap: '20px',
    padding: '20px'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
    marginBottom: '20px',
    flexWrap: 'wrap' as const
  },
  sortButton: {
    padding: '8px 16px',
    fontSize: '14px',
    borderRadius: '4px',
    border: 'none',
    color: 'white',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    fontWeight: 'bold',
    minWidth: '180px',
    '&:hover': {
      opacity: 0.9
    }
  }
};

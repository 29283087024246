import { President, MonthlyPrice } from '../types/president';

// Parse Gas Price CSV string into price data
function parseGasPriceCSVData(csvContent: string): Map<string, { price: number; change: number }> {
  const priceMap = new Map<string, { price: number; change: number }>();
  const lines = csvContent.split('\n').slice(1); // Skip header
  
  lines.forEach(line => {
    const [, year, period, , price, change] = line.split(',');
    if (!year || !period || !price) return;
    
    const month = period.substring(1).padStart(2, '0');
    const date = `${year}-${month}`;
    const changeValue = change?.trim() === 'N/A' ? 0 : parseFloat(change || '0');
    
    priceMap.set(date, {
      price: parseFloat(price),
      change: changeValue
    });
  });
  
  return priceMap;
}

// Parse Egg Price CSV string into price data
function parseEggPriceCSVData(csvContent: string): Map<string, { price: number; change: number }> {
  const priceMap = new Map<string, { price: number; change: number }>();
  const lines = csvContent.split('\n').slice(1); // Skip header
  let prevPrice = 0;

  lines.forEach(line => {
    const [, year, period, eggPrice] = line.split(',');
    if (!year || !period || !eggPrice) return;

    const price = parseFloat(eggPrice);
    
    const month = period.substring(1).padStart(2, '0');
    const date = `${year}-${month}`;
    const change = prevPrice === 0 ? 0 : ((price - prevPrice) / prevPrice) * 100;
    
    priceMap.set(date, {
      price,
      change,
    });

    prevPrice = price;

  });
  console.log(priceMap);
  
  return priceMap;
}

// Parse S&P 500 CSV string into price data
function parseSP500CSVData(csvContent: string): Map<string, { price: number; change: number }> {
  const priceMap = new Map<string, { price: number; change: number }>();
  const lines = csvContent.split('\n').slice(1); // Skip header
  let prevPrice = 0;
  
  lines.forEach(line => {
    const [date, sp500] = line.split(',');
    if (!date || !sp500) return;
    
    const price = parseFloat(sp500);
    if (isNaN(price)) return;

    const change = prevPrice === 0 ? 0 : ((price - prevPrice) / prevPrice) * 100;
    const [year, month] = date.split('-');
    const formattedDate = `${year}-${month}`;
    
    priceMap.set(formattedDate, {
      price,
      change
    });
    
    prevPrice = price;
  });
  
  return priceMap;
}

// Parse Electricity Price CSV string into price data
function parseElectricityCSVData(csvContent: string): Map<string, { price: number; change: number }> {
  const priceMap = new Map<string, { price: number; change: number }>();
  const lines = csvContent.split('\n').slice(1); // Skip header
  let prevPrice = 0;
  
  lines.forEach(line => {
    const [, year, period, value] = line.split(',');
    if (!year || !period || !value) return;
    
    const price = parseFloat(value);
    if (isNaN(price)) return;

    const month = period.substring(1).padStart(2, '0');
    const date = `${year}-${month}`;
    
    const change = prevPrice === 0 ? 0 : ((price - prevPrice) / prevPrice) * 100;
    
    priceMap.set(date, {
      price,
      change
    });
    
    prevPrice = price;
  });
  
  return priceMap;
}

// Parse Unemployment CSV string into price data
function parseUnemploymentCSVData(csvContent: string): Map<string, { price: number; change: number }> {
  const priceMap = new Map<string, { price: number; change: number }>();
  const lines = csvContent.split('\n').slice(1); // Skip header
  let prevRate = 0;
  
  lines.forEach(line => {
    const [, year, period, value] = line.split(',');
    if (!year || !period || !value) return;
    
    const rate = parseFloat(value);
    if (isNaN(rate)) return;

    // Convert M01 format to 01
    const month = period.substring(1).padStart(2, '0');
    const date = `${year}-${month}`;
    
    // For unemployment, we want absolute change in percentage points
    const change = prevRate === 0 ? 0 : rate - prevRate;
    
    priceMap.set(date, {
      price: rate,
      change
    });
    
    prevRate = rate;
  });
  
  return priceMap;
}

function generateMonthlyPrices(
  startDate: string, 
  endDate: string, 
  priceMap: Map<string, { price: number; change: number }>
): MonthlyPrice[] {
  const prices: MonthlyPrice[] = [];
  const start = new Date(startDate);
  const end = new Date(endDate);
  
  for (let date = start; date <= end; date.setMonth(date.getMonth() + 1)) {
    const dateKey = date.toISOString().slice(0, 7);
    const priceData = priceMap.get(dateKey);
    
    if (priceData) {
      const monthPrice: MonthlyPrice = {
        date: dateKey,
        price: priceData.price,
        monthOverMonthChange: priceData.change
      };
      prices.push(monthPrice);
    }
  }
  
  return prices;
}

function calculateAverageIncrease(prices: MonthlyPrice[]): number {
  if (prices.length === 0) return 0;
  const increases = prices.map(price => price.monthOverMonthChange);
  return Number((increases.reduce((a, b) => a + b, 0) / increases.length).toFixed(2));
}

const presidents: President[] = [
  {
    name: "Gerald Ford",
    party: "Republican",
    termStart: "1974-08",
    termEnd: "1977-01",
    prices: [],
    averageMonthlyIncrease: 0
  },
  {
    name: "Jimmy Carter",
    party: "Democrat",
    termStart: "1977-01",
    termEnd: "1981-01",
    prices: [],
    averageMonthlyIncrease: 0
  },
  {
    name: "Ronald Reagan",
    party: "Republican",
    termStart: "1981-01",
    termEnd: "1989-01",
    prices: [],
    averageMonthlyIncrease: 0
  },
  {
    name: "George H. W. Bush",
    party: "Republican",
    termStart: "1989-01",
    termEnd: "1993-01",
    prices: [],
    averageMonthlyIncrease: 0
  },
  {
    name: "Bill Clinton",
    party: "Democrat",
    termStart: "1993-01",
    termEnd: "2001-01",
    prices: [],
    averageMonthlyIncrease: 0
  },
  {
    name: "George W. Bush",
    party: "Republican",
    termStart: "2001-01",
    termEnd: "2009-01",
    prices: [],
    averageMonthlyIncrease: 0
  },
  {
    name: "Barack Obama",
    party: "Democrat",
    termStart: "2009-01",
    termEnd: "2017-01",
    prices: [],
    averageMonthlyIncrease: 0
  },
  {
    name: "Donald Trump",
    party: "Republican",
    termStart: "2017-01",
    termEnd: "2021-01",
    prices: [],
    averageMonthlyIncrease: 0
  },
  {
    name: "Joe Biden",
    party: "Democrat",
    termStart: "2021-01",
    termEnd: "2024-03",
    prices: [],
    averageMonthlyIncrease: 0
  }
];

export async function loadGasPriceData(): Promise<President[]> {
  try {
    const response = await fetch('/data/historicalGasPrice.csv');
    const csvContent = await response.text();
    const priceMap = parseGasPriceCSVData(csvContent);

    return presidents.map(president => ({
      ...president,
      prices: generateMonthlyPrices(president.termStart, president.termEnd, priceMap),
      get averageMonthlyIncrease() {
        return calculateAverageIncrease(this.prices);
      }
    }));
  } catch (error) {
    console.error('Error loading gas price data:', error);
    return presidents;
  }
}

export async function loadEggPriceData(): Promise<President[]> {
  try {
    const response = await fetch('/data/historicalEggPrice.csv');
    const csvContent = await response.text();
    const priceMap = parseEggPriceCSVData(csvContent);

    return presidents.map(president => ({
      ...president,
      prices: generateMonthlyPrices(president.termStart, president.termEnd, priceMap),
      get averageMonthlyIncrease() {
        return calculateAverageIncrease(this.prices);
      }
    }));
  } catch (error) {
    console.error('Error loading egg price data:', error);
    return presidents;
  }
}

export async function loadIncomeData(dataType: string): Promise<President[]> {
  try {
    const response = await fetch(`/data/${dataType}.csv`);
    const csvContent = await response.text();
    const priceMap = parseIncome20CSVData(csvContent);

    return presidents.map(president => ({
      ...president,
      prices: generateMonthlyPrices(president.termStart, president.termEnd, priceMap),
      get averageMonthlyIncrease() {
        return calculateAverageIncrease(this.prices);
      }
    }));
  } catch (error) {
    console.error('Error loading income data:', error);
    return presidents;
  }
}

// example lines
// Series ID,Year,Period,Label,Value
// CXUINCAFTAXLB0102M,1984,A01,1984 Annual,3137
// CXUINCAFTAXLB0102M,1985,A01,1985 Annual,3463
function parseIncome20CSVData(csvContent: string): Map<string, { price: number; change: number }> {
  const priceMap = new Map<string, { price: number; change: number }>();
  const lines = csvContent.split('\n').slice(1); // Skip header
  let prevPrice = 0;
  
  lines.forEach(line => {
    const [, year, period, , value] = line.split(',');
    if (!year || !period || !value) return;

    const price = parseFloat(value);
    if (isNaN(price)) return;

    const month = period.substring(1).padStart(2, '0');
    const date = `${year}-${month}`;
    const change = prevPrice === 0 ? 0 : ((price - prevPrice) / prevPrice);
    // monthChange is the 12th root of change since change is yearly
    const monthChange = (Math.pow(1 + change, 1 / 12) - 1) * 100;

    priceMap.set(date, {
      price,
      change: monthChange
    });
    
    prevPrice = price;
  });
  
  return priceMap;
}

export async function loadSP500Data(): Promise<President[]> {
  try {
    const response = await fetch('/data/historicalSP500.csv');
    const csvContent = await response.text();
    const priceMap = parseSP500CSVData(csvContent);

    return presidents.map(president => ({
      ...president,
      prices: generateMonthlyPrices(president.termStart, president.termEnd, priceMap),
      get averageMonthlyIncrease() {
        return calculateAverageIncrease(this.prices);
      }
    }));
  } catch (error) {
    console.error('Error loading S&P 500 data:', error);
    return presidents;
  }
}

export async function loadElectricityData(): Promise<President[]> {
  try {
    const response = await fetch('/data/historicalElectricityperKwh.csv');
    const csvContent = await response.text();
    const priceMap = parseElectricityCSVData(csvContent);

    return presidents.map(president => ({
      ...president,
      prices: generateMonthlyPrices(president.termStart, president.termEnd, priceMap),
      get averageMonthlyIncrease() {
        return calculateAverageIncrease(this.prices);
      }
    }));
  } catch (error) {
    console.error('Error loading electricity data:', error);
    return presidents;
  }
}

export async function loadUnemploymentData(): Promise<President[]> {
  try {
    const response = await fetch('/data/historicalUnemployment.csv');
    const csvContent = await response.text();
    const priceMap = parseUnemploymentCSVData(csvContent);

    return presidents.map(president => ({
      ...president,
      prices: generateMonthlyPrices(president.termStart, president.termEnd, priceMap),
      get averageMonthlyIncrease() {
        return calculateAverageIncrease(this.prices);
      }
    }));
  } catch (error) {
    console.error('Error loading unemployment data:', error);
    return presidents;
  }
}

export const presidentData = presidents;

export function calculateOverallAverageIncrease(presidents: President[]): number {
  return Number(
    (presidents.reduce((sum, pres) => sum + pres.averageMonthlyIncrease, 0) / presidents.length).toFixed(2)
  );
}

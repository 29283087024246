import React, { useState } from 'react';
import { President } from '../types/president';
import { chartStyles } from '../components/styles';
import { DataType } from './ChartControls';
import { PresidentBarChart } from './PresidentBarChart';

interface PresidentSummaryProps {
  presidentData: President[];
  overallAverage: number;
  dataType?: DataType;
}

export const PresidentSummary: React.FC<PresidentSummaryProps> = ({ presidentData, overallAverage, dataType }) => {
  const [sortByPerformance, setSortByPerformance] = useState(false);
  
  // Filter out presidents with no data
  const presidentsWithData = presidentData.filter(president => president.prices.length > 0);
  
  // Determine which metrics should be sorted where lower is better
  const isLowerBetter = dataType === 'unemployment' || 
                       dataType === 'gas' || 
                       dataType === 'electricity' || 
                       dataType === 'egg';

  const unit = dataType === 'unemployment' ? ' percentage points' : '%';

  const sortedData = sortByPerformance 
    ? [...presidentsWithData].sort((a, b) => {
        // For metrics where lower is better (unemployment, gas, electricity, eggs), 
        // we want smaller numbers first
        return isLowerBetter
          ? a.averageMonthlyIncrease - b.averageMonthlyIncrease
          : b.averageMonthlyIncrease - a.averageMonthlyIncrease;
      })
    : presidentsWithData;

  const getSortMessage = () => {
    if (!sortByPerformance) return 'chronological order';
    return isLowerBetter 
      ? 'performance order (lower is better)'
      : 'performance order (higher is better)';
  };

  return (
    <div style={chartStyles.presidentSummaryContainer}>
      <h2 style={{...chartStyles.sectionTitle, marginBottom: '20px', textAlign: 'center'}}>
        Presidential Terms and Average Monthly Changes
      </h2>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '12px',
        marginBottom: '20px',
      }}>
        <p style={{
          color: '#666',
          margin: 0,
          fontSize: '14px'
        }}>
          Currently showing presidents in {getSortMessage()}
        </p>
        <button 
          onClick={() => setSortByPerformance(!sortByPerformance)}
          style={{
            padding: '8px 16px',
            fontSize: '14px',
            borderRadius: '4px',
            border: 'none',
            color: 'white',
            cursor: 'pointer',
            transition: 'all 0.2s ease',
            fontWeight: 'bold',
            minWidth: '180px',
            backgroundColor: sortByPerformance ? '#0f8b8d' : '#6c757d'
          }}
        >
          {sortByPerformance ? '↺ Show Chronological' : '↓ Sort by Performance'}
        </button>
      </div>

      <PresidentBarChart 
        presidents={presidentData}
        sortType={sortByPerformance ? 'performance' : 'chronological'}
        metric={dataType || 'gas'}
      />

      <div style={chartStyles.presidentGridContainer}>
        {sortedData.map((president: President) => (
          <div 
            key={president.name}
            style={president.party === 'Democrat' ? chartStyles.democratCard : chartStyles.republicanCard}
          >
            <h3 style={president.party === 'Democrat' ? chartStyles.democratTitle : chartStyles.republicanTitle}>
              {president.name}
            </h3>
            <p style={chartStyles.partyText}>Term: {president.termStart} to {president.termEnd}</p>
            <p style={chartStyles.partyStatsText}>
              Avg Monthly Change: {president.averageMonthlyIncrease > 0 ? '+' : ''}{president.averageMonthlyIncrease.toFixed(2)}{unit}
            </p>
            <p style={chartStyles.partyRelativeText}>
              Relative to Overall Avg: {(president.averageMonthlyIncrease - overallAverage) > 0 ? '+' : ''}{(president.averageMonthlyIncrease - overallAverage).toFixed(2)}{unit}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

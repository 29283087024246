import { useState, useEffect } from 'react';
import { loadIncomeData, loadEggPriceData, loadGasPriceData, loadSP500Data, loadElectricityData, loadUnemploymentData } from '../data/presidentData';
import { President } from '../types/president';
import { DataType } from '../components/ChartControls';

export interface ChartData {
  visibleData: any[];
  presidentData: President[];
  overallAverage: number;
}

export const useChartData = (dataType: DataType): {
  data: ChartData;
  loading: boolean;
  error: string | null;
  handleRangeChange: (range: string) => void;
} => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [presidentData, setPresidentData] = useState<President[]>([]);
  const [overallAverage, setOverallAverage] = useState(0);
  const [visibleData, setVisibleData] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data;
        switch (dataType) {
          case 'sp500':
            data = await loadSP500Data();
            break;
          case 'electricity':
            data = await loadElectricityData();
            break;
          case 'unemployment':
            data = await loadUnemploymentData();
            break;
          case 'egg':
            data = await loadEggPriceData();
            break;
          case 'incomeQ1':
          case 'incomeQ2':
          case 'incomeQ3':
          case 'incomeQ4':
          case 'incomeQ5':
            data = await loadIncomeData(dataType);
            break;
          default:
            data = await loadGasPriceData();
        }
        
        setPresidentData(data);
        const avgIncrease = data.reduce((sum, pres) => sum + pres.averageMonthlyIncrease, 0) / data.length;
        setOverallAverage(Number(avgIncrease.toFixed(2)));
        
        const allData = data.flatMap(president => 
          president.prices.map(price => ({
            ...price,
            president: president.name,
            party: president.party
          }))
        );
        
        setVisibleData(allData);
        setLoading(false);
      } catch (err) {
        setError('Failed to load data');
        setLoading(false);
      }
    };

    fetchData();
  }, [dataType]);

  const handleRangeChange = (range: string) => {
    const data = presidentData.flatMap(president => 
      president.prices.map(price => ({
        ...price,
        president: president.name,
        party: president.party
      }))
    );

    let filteredData;
    const now = new Date();
    switch (range) {
      case '5y':
        const fiveYearsAgo = new Date(now.getFullYear() - 5, now.getMonth());
        filteredData = data.filter(d => new Date(d.date) >= fiveYearsAgo);
        break;
      case '10y':
        const tenYearsAgo = new Date(now.getFullYear() - 10, now.getMonth());
        filteredData = data.filter(d => new Date(d.date) >= tenYearsAgo);
        break;
      case '20y':
        const twentyYearsAgo = new Date(now.getFullYear() - 20, now.getMonth());
        filteredData = data.filter(d => new Date(d.date) >= twentyYearsAgo);
        break;
      default:
        filteredData = data;
    }
    
    setVisibleData(filteredData);
  };

  return {
    data: {
      visibleData,
      presidentData,
      overallAverage
    },
    loading,
    error,
    handleRangeChange
  };
};

import React from 'react';
import { chartStyles } from '../components/styles';

interface TimeRange {
  label: string;
  value: string;
}

export type DataType = 'gas' | 'sp500' | 'electricity' | 'unemployment' | 'egg' | 'incomeQ1' | 'incomeQ2'| 'incomeQ3' | 'incomeQ4' |'incomeQ5';

interface ChartControlsProps {
  dataType: DataType;
  currentRange: string;
  onDataTypeChange: (type: DataType) => void;
  onRangeChange: (range: string) => void;
}

const timeRanges: TimeRange[] = [
  { label: 'All Time', value: 'all' },
  { label: 'Last 20 Years', value: '20y' },
  { label: 'Last 10 Years', value: '10y' },
  { label: 'Last 5 Years', value: '5y' },
];

export const ChartControls: React.FC<ChartControlsProps> = ({
  dataType,
  currentRange,
  onDataTypeChange,
  onRangeChange
}) => (
  <div style={chartStyles.controlsContainer}>
    <div style={chartStyles.toggleContainer}>
      <select
        value={dataType}
        onChange={(e) => onDataTypeChange(e.target.value as DataType)}
        style={chartStyles.select}
      >
        <option value="gas">Gas Prices</option>
        <option value="sp500">S&P 500</option>
        <option value="electricity">Electricity Prices</option>
        <option value="unemployment">Unemployment Rate</option>
        <option value="egg">Egg Prices</option>
        <option value="incomeQ1">Income (Bottom Quintile)</option>
        <option value="incomeQ2">Income (Second Quintile)</option>
        <option value="incomeQ3">Income (Third Quintile)</option>
        <option value="incomeQ4">Income (Fourth Quintile)</option>
        <option value="incomeQ5">Income (Top Quintile)</option>
      </select>
    </div>
    <div style={chartStyles.rangeButtonContainer}>
      {timeRanges.map(range => (
        <button
          key={range.value}
          onClick={() => onRangeChange(range.value)}
          style={{
            ...chartStyles.rangeButton,
            ...(currentRange === range.value ? chartStyles.activeRangeButton : {})
          }}
        >
          {range.label}
        </button>
      ))}
    </div>
  </div>
);

import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell
} from 'recharts';
import { President } from '../types/president';
import { DataType } from './ChartControls';
import { chartStyles } from './styles';

interface PresidentBarChartProps {
  presidents: President[];
  sortType: 'chronological' | 'performance';
  metric: string;
}

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div style={{ 
        backgroundColor: 'rgba(255, 255, 255, 0.95)', 
        padding: '10px', 
        border: '1px solid #ccc',
        fontSize: '12px'
      }}>
        <p style={{ margin: '0 0 5px 0' }}><strong>President:</strong> {data.name}</p>
        <p style={{ margin: '0 0 5px 0' }}>
          <strong>Average Monthly Change:</strong> {data.averageMonthlyIncrease.toFixed(2)}
          {data.dataType === 'unemployment' ? ' percentage points' : '%'}
        </p>
        <p style={{ margin: '0' }}><strong>Term:</strong> {data.termStart} - {data.termEnd}</p>
      </div>
    );
  }
  return null;
};

export const PresidentBarChart: React.FC<PresidentBarChartProps> = ({
  presidents,
  sortType,
  metric
}) => {
  // Filter out presidents with no data
  const presidentsWithData = presidents.filter(president => president.prices.length > 0);
  
  // Determine which metrics should be sorted where lower is better
  const isLowerBetter = metric === 'unemployment' || 
                       metric === 'gas' || 
                       metric === 'electricity' || 
                       metric === 'egg';

  const sortedData = sortType === 'performance'
    ? [...presidentsWithData].sort((a, b) => {
        return isLowerBetter
          ? a.averageMonthlyIncrease - b.averageMonthlyIncrease
          : b.averageMonthlyIncrease - a.averageMonthlyIncrease;
      })
    : presidentsWithData;

  return (
    <div style={{ width: '100%', height: 400, marginBottom: '2rem' }}>
      <ResponsiveContainer>
        <BarChart
          data={sortedData}
          margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            angle={-45}
            textAnchor="end"
            height={100}
            interval={0}
            tick={{ fontSize: 12 }}
          />
          <YAxis
            label={{ 
              value: 'Average Monthly Change', 
              angle: -90,
              position: 'insideLeft',
              style: { textAnchor: 'middle' }
            }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="averageMonthlyIncrease">
            {sortedData.map((entry, index) => (
              <Cell 
                key={`cell-${index}`}
                fill={entry.party === 'Democrat' ? '#0f8b8d' : '#b0413e'}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
